<template>
  <picture>
    <source
      v-for="entry in sources"
      :key="entry.media"
      :srcset="computeSrc(entry.props)"
      :media="entry.media"
    />
    <img
      :src="
        computeSrc({ width: minWidth, height: minHeight, mode: defaultMode })
      "
      :srcset="
        computeSrc({
          width: minWidth,
          height: minHeight,
          mode: defaultMode,
          density: '2x',
        })
      "
      :alt="alt"
    />
  </picture>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    sources: {
      type: Array,
      required: true,
    },
    minWidth: {
      type: Number,
      required: true,
    },
    minHeight: {
      type: Number,
      required: true,
    },
    defaultMode: {
      type: String,
      default: 'crop',
    },
    alt: {
      type: String,
      default: '',
    },
  },
  computed: {
    computeSrc() {
      return ({ width, height, mode, density, src = this.src }) => {
        if (mode) {
          mode = this.defaultMode
        }
        let imgSrc = `${src}&width=${width}&height=${height}&fit=${mode}`
        if (density) {
          imgSrc = `${imgSrc}, ${src}&width=${width * 2}&height=${
            height * 2
          }&fit=${mode} ${density}`
        }

        return imgSrc
      }
    },
  },
}
</script>
