<template>
  <header
    :class="{
      'header--backdrop': backdrop,
      'header--sticky': stickyMobileHeader,
    }"
  >
    <BaseImg
      v-if="backdrop"
      :src="backdrop"
      :sources="$options.sources"
      class="header__backdrop"
      :min-width="390"
      :min-height="200"
      default-mode="contain"
      alt="page-header-image"
    />
    <div
      :class="[
        'container header-container d-flex justify-content-start align-items-center',
        { 'justify-content-center': centeredLogo },
      ]"
    >
      <div
        v-if="isDefault"
        class="d-flex justify-content-between justify-content-md-start align-items-center w-100"
      >
        <div class="header__menu-hamburger col-sm-4 col-md-4 pl-0">
          <HamburgerBtn />
        </div>
        <NuxtLink
          to="/"
          class="col-sm-4 col-md-4 col-lg-3 pl-0 logo-wrapper"
          aria-label="homepage"
        >
          <div class="d-flex">
            <BookchoiceLogo v-if="defaultLogo" class="logo" />
            <BookchoicePromoLogo v-else class="logo" :fill="color" />
          </div>
        </NuxtLink>
        <DesktopMenu class="col-sm-4 col-md-4 col-lg-9" />
      </div>
      <NuxtLink
        v-else
        to="/"
        class="col-12 col-lg-3 pl-0 logo-wrapper"
        aria-label="homepage"
      >
        <div :class="['d-flex', { 'justify-content-center': centeredLogo }]">
          <BookchoiceLogo v-if="defaultLogo" class="logo" />
          <BookchoicePromoLogo v-else class="logo" :fill="color" />
        </div>
      </NuxtLink>
    </div>
    <slot></slot>
  </header>
</template>

<script>
import DesktopMenu from '~/components/menu/DesktopMenu'
import BaseImg from './BaseImg'

import BookchoiceLogo from '~/assets/icons/bookchoice-logo.svg'
import BookchoicePromoLogo from '~/assets/icons/bookchoice-logo-promo.svg'
import HamburgerBtn from '~/components/buttons/HamburgerBtn'

export default {
  components: {
    DesktopMenu,
    BaseImg,
    BookchoiceLogo,
    BookchoicePromoLogo,
    HamburgerBtn,
  },
  props: {
    isDefault: {
      type: Boolean,
      required: true,
    },
    defaultLogo: {
      type: Boolean,
      default: true,
    },
    backdrop: {
      type: String,
      default: '',
    },
    srcPerSize: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: '#fff',
    },
    dividerColor: {
      type: String,
      default: undefined,
    },
    stickyMobileHeader: {
      type: Boolean,
      default: false,
    },
    centeredLogo: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const {
      headerBackgroundLargeImageUrl,
      headerBackgroundMediumImageUrl,
      headerBackgroundSmallImageUrl,
    } = this.srcPerSize

    this.$options.sources = [
      {
        key: 'lg',
        media: '(min-width: 1024px)',
        props: {
          src: headerBackgroundLargeImageUrl || this.backdrop,
          mode: 'contain',
          width: 1600,
          height: 600,
          density: '2x',
        },
      },
      {
        key: 'md',
        media: '(min-width: 768px)',
        props: {
          src: headerBackgroundMediumImageUrl || this.backdrop,
          mode: 'contain',
          width: 1024,
          height: 460,
          density: '2x',
        },
      },
      {
        key: 'sm',
        media: '(min-width: 576px)',
        props: {
          src: headerBackgroundSmallImageUrl || this.backdrop,
          mode: 'contain',
          width: 768,
          height: 460,
          density: '2x',
        },
      },
    ]
  },
}
</script>

<style lang="scss" scoped>
header {
  @include media-breakpoint-only(xs) {
    border-bottom: 1px solid $white;
  }
}

.header-container {
  display: flex;
  height: 57px;
}

.header__menu-hamburger {
  display: none;
}

.logo {
  height: 37px;
}

@include media-breakpoint-down(lg) {
  .header--sticky {
    position: sticky;
    top: 0;
    z-index: 15;
    border-bottom: none;
    background-color: #fff;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
  }

  .header__menu-hamburger {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .header-container {
    height: 96px;
  }

  .logo {
    height: 63px;
  }
}
</style>
