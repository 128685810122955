<script setup>
const { updateDrawerState } = useDrawer()

function openMenu() {
  const opt = {
    menuPositionClass: 'left',
    title: 'Menu',
    component: 'MobileMenu',
  }

  updateDrawerState({
    drawerOptions: opt,
    isDrawerShown: true,
  })
}
</script>

<template>
  <BaseButton
    aria-label="hamburger menu"
    variant="naked"
    class="reset-btn-styling d-flex flex-column justify-content-center align-items-center position-relative"
    @click="openMenu"
  >
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </BaseButton>
</template>

<style lang="scss" scoped>
button {
  width: 24px;
  height: 24px;

  &:focus {
    .line {
      opacity: 0;

      &:nth-child(1) {
        transform: rotate(20deg);
      }

      &:nth-child(3) {
        transform: rotate(-20deg);
        position: absolute;
        top: 6px;
      }

      &:nth-child(2) {
        transition: none;
        opacity: 0;
      }
    }
  }
}
.line {
  background-color: $black;
  transition: all 0.3s ease-out;
  display: block;
  height: 2px;
  width: 16px;
  border-radius: 100px;
  margin: 3px 0;
  opacity: 1;
}
</style>
