<template>
  <div data-nw="user-menu-area">
    <div
      data-nw="open-user-menu"
      class="d-flex d-lg-none d-xl-none"
      @click.prevent="openUserMenu()"
    >
      <UserIcon width="24px" height="24px" class="user-icon" />
    </div>
    <div class="user-area">
      <div v-if="isAuthenticated" class="d-flex justify-content-end">
        <BaseButton
          class="reset-btn-styling menu-links align-self-center"
          variant="link"
          aria-label="open user menu"
          @click.prevent="openUserMenu()"
        >
          <BaseUserProfilePicture
            :user-pic="loggedInUser.photo"
            :user-pic-type="'user-menu'"
          />
        </BaseButton>

        <BaseButton
          class="reset-btn-styling menu-links align-self-center ml-8"
          variant="link"
          data-nw="profile-menu-link"
          aria-label="open user menu"
          @click.prevent="openUserMenu()"
        >
          {{ loggedInUser.firstName }}
        </BaseButton>
      </div>
      <div v-else>
        <BaseButton
          aria-label="open user menu"
          data-testid="login-link"
          class="reset-btn-styling menu-links mr-32"
          variant="link"
          @click="openUserMenu()"
          >{{ $label('common.login') }}
        </BaseButton>
        <NuxtLink
          class="btn btn-call-to-action"
          to="/aanmelden"
          aria-label="aanmelden"
          data-nw="become-member"
          @click.enter="becomeMemberClicked"
        >
          {{ $label('common.joinUs') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseUserProfilePicture from '~/components/BaseUserProfilePicture'
import UserIcon from '~/assets/icons/user.svg'

export default {
  components: {
    BaseUserProfilePicture,
    UserIcon,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
      drawerOptions: 'drawerOptions',
      isDrawerShown: 'isDrawerShown',
    }),
    userFirstName() {
      return this.loggedInUser && this.loggedInUser.firstName
    },
  },
  methods: {
    ...mapActions({
      updateDrawerState: 'updateDrawerState',
    }),
    openUserMenu() {
      const userMenu = {
        menuPositionClass: 'right',
        title: this.userFirstName,
        component: 'base-menu-user',
      }
      const loginMenu = {
        menuPositionClass: 'right',
        title: 'user.signIn.title',
        component: 'base-login',
        props: {
          isHeadingShown: false,
          loginFormPrefix: 'login-',
          shouldPreventRedirect: true,
          inSlideMenu: true,
        },
      }
      const opt = this.isAuthenticated ? userMenu : loginMenu

      this.updateDrawerState({
        drawerOptions: opt,
        isDrawerShown: true,
      })
    },
    becomeMemberClicked() {
      this.$gtmTracking.becomeMemberClicked('BaseMenuUserArea')
    },
  },
}
</script>

<style lang="scss" scoped>
.header-menu__icon {
  color: $grey-900;
}

.user-area {
  align-self: center;
  display: flex;
  justify-content: flex-end;
}

.user-pic__photo {
  border: 2px solid $white;
}

.user-pic__icon {
  background-color: $grey-50;
  border: 2px solid $white;
  border-radius: 50%;
}

@include media-breakpoint-down(lg) {
  .user-area {
    display: none;
  }
}
</style>
